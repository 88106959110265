import React from 'react'

import './Title.css';

function Title() {
  return (
    <div className="Title">
        <h1 className="Title-h1">D Ten Thousand</h1>
            <div className="Title-Subtitle">Extended Wildmagic Table</div>
    </div>
   
  )
}

export default Title